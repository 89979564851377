import React, { Fragment, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { AnimatePresence, motion } from "framer-motion"

//components
import RightArrow from "../components/rightArrow"
import { variants } from "../utils/framerUtils"

const ConditionalLinkWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const NewsGridItem = ({
  imageData,
  altText,
  setArticle,
  index,
  title,
  subtitle,
  text,
  link,
}) => {
  const [hovered, setHovered] = useState(false)
  return (
    <motion.div
      variants={variants.fadeUpandIn}
      className={`h-max relative pb-lead-2 mb-lead-2 col-span-1 md:col-span-3 md:hover:grayscale hover:cursor-pointer ${
        index % 2 === 0 ? "col-start-1" : "md:col-start-5"
      }`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={!link ? () => setArticle() : undefined}
    >
      <ConditionalLinkWrapper
        condition={link}
        wrapper={children => <a href={link}>{children}</a>}
      >
        <>
          <GatsbyImage
            image={imageData}
            alt={altText || "A LoveArt Feature Image"}
          />
          <h3 className="font-light md:text-desktop-medium text-mobile-medium my-[0.65rem]">
            <span>{title}</span>
            {/* Hide date on Love[f]art ase reqeusted */}
            {subtitle ? (
              <span className="text-love-grey pl-[0.6rem]">{subtitle}</span>
            ) : null}
          </h3>
          <p className="text-love-grey mb-lead-1">{text}</p>
          {/* <p
            className={`opacity-0 flex items-center font-light  md:text-desktop-medium text-mobile-medium ${
              hovered ? "md:opacity-100" : "opacity-0"
            }`}
          >
            <span className="mr-[0.65rem]">View</span>
            <RightArrow />
          </p> */}
          <AnimatePresence>
            {hovered && (
              <motion.div
                key={title}
                className="absolute bottom-0 overflow-hidden"
              >
                <motion.p
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 30 }}
                  className={`flex items-center font-light  md:text-desktop-medium text-mobile-medium`}
                >
                  <span className="mr-[0.65rem]">View</span>
                  <RightArrow />
                </motion.p>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      </ConditionalLinkWrapper>
    </motion.div>
  )
}

export default NewsGridItem
