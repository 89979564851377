import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import Seo from "../components/seo"

//components
import Layout from "../components/layout"
import Sidebar from "../components/sidebar"
import NewsGridItem from "../components/newsGridItem"
import TransitionOverlay from "../components/transitionOverlay"

//utils
import { variants } from "../utils/framerUtils"

function formatArticleDate(rawDateString) {
  const splitDate = rawDateString.split("-")
  const twoDigitYear = splitDate[0].slice(2, 4)
  splitDate[0] = twoDigitYear
  return splitDate.reverse().join(".")
}

const News = ({ data, location }) => {
  //Effect to fix bug that was making page scroll to position of previous page
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0 })
    }
  }, [])
  //transition logic
  const referrerURL = location?.state?.referrer
  const splitFilteredURL = referrerURL
    ? referrerURL.split("/").filter(item => item)
    : []
  const [selectedArticle, setSelectedArticle] = useState(null)
  const [fromArticle, setFromArticle] = useState(
    splitFilteredURL.includes("article") && splitFilteredURL.length > 1
  )
  return (
    <Layout footer>
      {(selectedArticle || fromArticle) && (
        <TransitionOverlay
          from={fromArticle}
          selectedItem={selectedArticle}
          setFrom={setFromArticle}
          navigateTo={`/news/article/${selectedArticle}`}
        />
      )}
      <Sidebar pageTitle="NEWS" />
      <motion.div
        variants={variants.fadeUpandInStaggerChildren}
        className="md:col-start-5 md:col-span-7 md:grid md:grid-cols-7 gap-lead-1 pt-lead-5 md:pt-0"
      >
        {data.allSanityNewsItem.edges.map(({ node }, index) => {
          const formattedDate = formatArticleDate(node.publishedAt)
          return (
            <NewsGridItem
              key={node.id}
              imageData={node.featureImage.asset.gatsbyImageData}
              altText={node.featureImage.altText}
              title={node.title}
              subtitle={formattedDate}
              text={node.itemDescription}
              index={index}
              link={node.externalLink || node.linkToFile?.asset.url}
              setArticle={() => setSelectedArticle(node.slug.current)}
            />
          )
        })}
      </motion.div>
    </Layout>
  )
}

export default News
export const Head = () => <Seo title="News" />
//data
export const query = graphql`
  query {
    allSanityNewsItem(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          featureImage {
            asset {
              gatsbyImageData
            }
            altText
          }
          itemType
          slug {
            current
          }
          title
          externalLink
          itemDescription
          publishedAt
          linkToFile {
            asset {
              url
            }
          }
        }
      }
    }
  }
`
